import { inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { assertInjector } from 'ngxtension/assert-injector';
import { map } from 'rxjs';

/**
 * The `injectQueryParams` function allows you to access and manipulate query parameters from the current route.
 *
 * @template ReadT - The expected type of the read value.
 * @param {string} keyOrParamsTransform - The name of the query parameter to retrieve, or a parse function to apply to the query parameters object.
 * @param {QueryParamsOptions} options - Optional configuration options for the query parameter.
 * @returns {Signal} A `Signal` that emits the parsed value of the specified query parameter, or the entire query parameters object if no key is provided.
 *
 * @example
 * const search = injectQueryParams('search'); // returns the value of the 'search' query param
 * const search = injectQueryParams(p => p['search'] as string); // same as above but can be used with a custom parse function
 * const idParam = injectQueryParams('id', {parse: numberAttribute}); // returns the value fo the 'id' query params and parses it into a number
 * const idParam = injectQueryParams(p => numberAttribute(p['id'])); // same as above but can be used with a custom transform function
 * const queryParams = injectQueryParams(); // returns the entire query params object
 */
function injectQueryParams(keyOrParamsTransform, options = {}) {
  return assertInjector(injectQueryParams, options?.injector, () => {
    const route = inject(ActivatedRoute);
    const queryParams = route.snapshot.queryParams || {};
    const {
      parse,
      transform,
      initialValue,
      defaultValue
    } = options;
    if (!keyOrParamsTransform) {
      return toSignal(route.queryParams, {
        initialValue: queryParams
      });
    }
    if (typeof keyOrParamsTransform === 'function') {
      return toSignal(route.queryParams.pipe(map(keyOrParamsTransform)), {
        initialValue: keyOrParamsTransform(queryParams)
      });
    }
    const getParam = params => {
      const param = params?.[keyOrParamsTransform];
      if (!param) {
        return defaultValue ?? initialValue ?? null;
      }
      if (Array.isArray(param)) {
        if (param.length < 1) {
          return defaultValue ?? initialValue ?? null;
        }
        return parse ? parse(param[0]) : transform ? transform(param[0]) : param[0];
      }
      return parse ? parse(param) : transform ? transform(param) : param;
    };
    return toSignal(route.queryParams.pipe(map(getParam)), {
      initialValue: getParam(queryParams)
    });
  });
}
/**
 * The `injectQueryParams` function namespace provides additional functionality for handling array query parameters.
 */
(function (injectQueryParams) {
  /**
   * Retrieve an array query parameter with optional configuration options.
   *
   * @template ReadT - The expected type of the read value.
   * @param {string} key - The name of the array query parameter to retrieve.
   * @param {QueryParamsOptions} options - Optional configuration options for the array query parameter.
   * @returns {Signal} A `Signal` that emits an array of transformed values for the specified query parameter, or `null` if it's not present.
   */
  function array(key, options = {}) {
    return assertInjector(injectQueryParams.array, options?.injector, () => {
      const route = inject(ActivatedRoute);
      const queryParams = route.snapshot.queryParams || {};
      const {
        parse,
        transform,
        initialValue,
        defaultValue
      } = options;
      const transformParam = param => {
        if (!param) {
          return defaultValue ?? initialValue ?? null;
        }
        if (Array.isArray(param)) {
          if (param.length < 1) {
            return defaultValue ?? initialValue ?? null;
          }
          // Avoid passing the parse function directly into the map function,
          // because parse may inadvertently use the array index as its second argument.
          // Typically, map provides the array index as the second argument to its callback,
          // which can conflict with parse functions like numberAttribute that expect a fallbackValue as their second parameter.
          // This mismatch can lead to unexpected behavior, such as values being erroneously converted to array indices
          // instead of NaN (which would be correct)
          return parse ? param.map(it => parse(it)) : transform ? param.map(it => transform(it)) : param;
        }
        return [parse ? parse(param) : transform ? transform(param) : param];
      };
      const getParam = params => {
        const param = params?.[key];
        return transformParam(param);
      };
      return toSignal(route.queryParams.pipe(map(getParam)), {
        initialValue: getParam(queryParams)
      });
    });
  }
  injectQueryParams.array = array;
})(injectQueryParams || (injectQueryParams = {}));

/**
 * Generated bundle index. Do not edit.
 */

export { injectQueryParams };
